<template>
	<div>
        <div v-if="items" v-for="(item, i) in items" :key="i" >
            <v-row class="flex-nowrap" no-gutters>
                <Checkbox
                    v-model="new_val[item.id]"
                    :label="item.name || item.display_name"
                    class="mb-1 pa-0 col text-truncate"
                />
                <v-col cols="auto" style="flex: none" class="pa-0 cursor-pointer" @click.stop="item.child ? expanded = !expanded: ''">
					<v-icon :class="expanded ? '':'transition-90-rotate'" size="20" v-if="item.child">mdi-chevron-down</v-icon>
				</v-col>
            </v-row>
            
            <v-expand-transition v-if="item.child">
                <div v-show="expanded">
                    <NestedFilter v-model="new_val" class="pl-3" :items="item.child"/>
                </div>
            </v-expand-transition>
        </div>
	</div>
</template>

<script>
	export default {
		name: "NestedFilter",
		props: ['value', 'items'],
		components: {
			Checkbox: () => import('@/components/fields/Checkbox.vue'),
		},
        data(){
			return {
				expanded: true
			}
		},
		computed: {
			new_val: {
				get(){
				
					return this.value
				},
				set(val){
					this.$emit('input', val)
				}
			}
		}
	}
</script>

<style scoped>
	.field >>> .v-expansion-panel--active > .v-expansion-panel-header {
		height: 20px !important;
	}
</style>
